import React from 'react';
import { Avatar, Typography, Box, Button, Link } from '@mui/material';
import styled from 'styled-components';
import useTypewriter from 'react-typewriter-hook';
import HomePageImage from '../Images/homepagemain.png';
import { LinkedIn, MailOutline, Instagram } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';




const HomePageContainer = styled(Box)`
height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5em;
  background-color: #000;
  color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 1em;
    height: auto;
  }
`;

const IntroContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  @media (max-width: 768px) {
    text-align: center;
    align-items: center;
  }
`;

const AvatarContainer = styled(Avatar)`
  width: 300px !important;
  height: 300px !important;
  margin-left: auto;
  border: 3px solid white;

  @media (max-width: 768px) {
    width: 150px !important;
    height: 150px !important;
    margin: 0 auto;
  }
`;


const LeftSection = styled(Box)`
  order: 1; 
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  @media (max-width: 768px) {
    order: 2;
  }
`;

const RightSection = styled(Box)`
  order: 2; 
  width: 20%;
  background-color: #000;
  color: #fff;
  padding: 2em;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 0 auto;

  @media (max-width: 768px) {
    order: 3;
    width: 100%;
    center: 0;
    margin: 1em auto;
  }
`;

const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background-color: #fff;
  color: #000;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SkillsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const SkillItem = styled.li`
  margin-bottom: 1em;
`;

const StyledButton = styled(Button)`
  background-color: #000 !important;
  color: #fff !important;
  margin: 0px 10px !important;
  border-radius: 20px !important;
  &:hover {
    background-color: #333 !important;
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
  }
  @media (max-width: 768px) {
    margin: 10px 10px !important;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: underline;
  font-style: italic;
  color: #000 !important;
  margin: 0px 10px !important;
  color: #000;  // Change color as needed
`;


function HomePage() {
    const message = "Hi there 👋, my name is Murad and I'm a Mechanical ⚙️ / Software 💻 Engineer with a Passion for Sustainability 🌱.";
    const typewriterText = useTypewriter(message);
    const skills = ['Building Energy Modelling (BEM)', 'Life Cycle Assessment (LCA)', 'Computer Aided Design (CAD)', 'Finite Element Analysis (FEA)', 'Website Development']; // Add your skills here
    
    return (
        <>
          <HomePageContainer>
            <IntroContainer>
              <Typography variant="h2" component="div">
                {typewriterText}
              </Typography>
            </IntroContainer>
            <AvatarContainer alt="Profile Image" src={HomePageImage} />
          </HomePageContainer>
          <InfoContainer>
            <LeftSection>
              <Typography variant="h5">
                Thank you for visiting my website. You can find my detailed 
                <StyledLink href='/cv'>Work Experience</StyledLink>
                as well as my 
                <StyledLink href='/projects'>Projects</StyledLink>
                on here. 
              </Typography>
              <Box height="20px"></Box>
              <Typography variant="h5">
                I'm always looking for new collaborators and projects to be involved with. If you have anything in mind, I would love to hear from you!
              </Typography>
              <Box height="20px"></Box>
              <Box>
                <StyledButton startIcon={<MailOutline />} href="mailto:murad.gohar@mail.mcgill.ca" target="_blank" rel="noopener noreferrer">Email</StyledButton>
                <StyledButton startIcon={<LinkedIn />} href="https://linkedin.com/in/muradgohar" target="_blank" rel="noopener noreferrer">LinkedIn</StyledButton>
                <StyledButton startIcon={<Instagram />} href="https://instagram.com/murad_gohar" target="_blank" rel="noopener noreferrer">Instagram</StyledButton>
              </Box>
            </LeftSection>
            <RightSection onClick={() => window.location.href='/cv'}>
              <Typography variant="h5">
                Top Skills
              </Typography>
              <SkillsList>
                {skills.map(skill => (
                  <SkillItem key={skill}>{skill}</SkillItem>
                ))}
              </SkillsList>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <ArrowRightAltIcon />
                <Typography variant="body2">
                  Click to see more skills on my CV page
                </Typography>
              </Box>
            </RightSection>
          </InfoContainer>
        </>
      );
    }
    
    export default HomePage;
    