import React from 'react';
import { AppBar, Toolbar, Button, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

export default function NavBar() {
  return (
<AppBar position="sticky" sx={{ width: '100%' }}>
      <Toolbar sx={{ backgroundColor: '#000000', display: 'flex', justifyContent: 'center' }}>
        <Box>
          <Button 
            color="inherit" 
            component={Link} 
            to="/cv"
            sx={{ 
              '&:hover': {
                color: '#000000',
                backgroundColor: '#FFFFFF',
                transform: 'scale(1.1)',
                border: '1px solid #000000'
              }
            }}
          >
            CV
          </Button>
        </Box>
        <Box ml={2} mr={2}>
          <IconButton 
            color="inherit"
            component={Link} 
            to="/"
            sx={{ 
              '&:hover': {
                color: '#000000',
                backgroundColor: '#FFFFFF',
                transform: 'scale(1.1)',
                border: '1px solid #000000'
              }
            }}
          >
            <HomeIcon />
          </IconButton>
        </Box>
        <Box>
          <Button 
            color="inherit" 
            component={Link} 
            to="/projects"
            sx={{ 
              '&:hover': {
                color: '#000000',
                backgroundColor: '#FFFFFF',
                transform: 'scale(1.1)',
                border: '1px solid #000000'
              }
            }}
          >
            Projects
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
