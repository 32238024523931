import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Avatar, Typography, Box, Button, Link } from '@mui/material';
import styled from 'styled-components';
import useTypewriter from 'react-typewriter-hook';
import HomePageImage from '../Images/homepagemain.png';

const HomePageContainer = styled(Box)`
height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5em;
  background-color: #000;
  color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 1em;
    height: auto;
  }
`;

const IntroContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  @media (max-width: 768px) {
    text-align: center;
    align-items: center;
  }
`;

const AvatarContainer = styled(Avatar)`
  width: 400px !important;
  height: 400px !important;
  margin-left: auto;
  border: 3px solid white;

  @media (max-width: 768px) {
    width: 150px !important;
    height: 150px !important;
    margin: 0 auto;
  }
`;

const AvatarAnimation = styled.div`
  animation: drop-in 4s ease-in-out forwards;

  @keyframes drop-in {
    0% {
      transform: translateY(-100vh);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const identities = ["Mechanical ⚙️", "Software 💻", "Sustainability 🌱"];

const FadeInText = styled(Typography)`
  opacity: 0;
  transition: opacity 2s ease-in-out;

  &.visible {
    opacity: 1;
  }
`;


function HomePage() {
  const [identityIndex, setIdentityIndex] = useState(0);
  const [currentIdentity, setCurrentIdentity] = useState(identities[identityIndex]);
  const [isAvatarVisible, setAvatarVisible] = useState(false);
  const [isTextVisible, setTextVisible] = useState(false);


  useEffect(() => {

    setTimeout(() => {
      setAvatarVisible(true);
    }, 1000);

    setTimeout(() => {
      setTextVisible(true);
    }, 5000);

    const interval = setInterval(() => {
      setIdentityIndex((prevIdentityIndex) => {
        const newIdentityIndex = (prevIdentityIndex + 1) % identities.length;
        setCurrentIdentity(identities[newIdentityIndex]);
        return newIdentityIndex;
      });
    }, 2000);

    
  }, []);

  const initialMessage = "Hi there 👋, I'm Murad!";
  const typewriterText = useTypewriter(initialMessage);

  return (
    <>
      <HomePageContainer>
        <IntroContainer>
          <Box>
            <Typography variant="h1" component="div">
              {typewriterText}
            </Typography>
          </Box>
          <Box mt={10}>
            <FadeInText variant="h3" className={isTextVisible ? "visible" : ""}>
              A {currentIdentity} Engineer tackling some wicked problems
            </FadeInText>
          </Box>
        </IntroContainer>
        {isAvatarVisible && (
          <AvatarAnimation>
            <AvatarContainer alt="Profile Image" src={HomePageImage} />
          </AvatarAnimation>
        )}
      </HomePageContainer>

    </>
  );
}

export default HomePage;