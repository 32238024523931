import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './Components/NavBar';
import CVPage from './Pages/CVPage';
import ProjectsPage from './Pages/ProjectsPage';
import HomePage from './Pages/HomePage'; 
import HomeSecret from './Pages/HomeSecret';// import the new component here
import Playground from './Pages/Playground';
import Projects2 from './Pages/Projects2';

function App() {

/* eslint-disable no-restricted-globals */
if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}
/* eslint-enable no-restricted-globals */

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} /> {/* Use base route for HomePage */}
        <Route path="/cv" element={<CVPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/homesecret" element={<HomeSecret />} />
        <Route path="/playground" element={<Playground />} />
        <Route path="/projects2" element={<Projects2 />} />
      </Routes>
    </Router>
  );
}

export default App;
