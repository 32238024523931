import React, { useState } from "react";
import { Typography, Box, Button, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled, { createGlobalStyle } from 'styled-components';
import { Autocomplete, TextField, Grid, } from '@mui/material';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeatPumpProjectImage from '../Images/Heatpumps.png';
import CapstoneImage from '../Images/Capstone.png';
import VACmain from '../Images/VACmain.png';
import VAC1 from '../Images/VAC1.png';
import VAC2 from '../Images/VAC2.png';
import CMain from '../Images/CMain.png';
import CM1 from '../Images/CM1.png';
import CM2 from '../Images/CM2.png';
import CM3 from '../Images/CM3.png';
import CM4 from '../Images/CM4.png';
import CM5 from '../Images/CM5.png';
import CM6 from '../Images/CM6.png';
import CM7 from '../Images/CM7.png';
import NorthWest from '../Images/NorthWest.png';
import LCA from '../Images/LCA.png';
import MB20 from '../Images/MB20.png';
import MB20CAD from '../Images/MB20CAD.png';
import eVTOL1 from '../Images/eVTOL1.png';
import eVTOL2 from '../Images/eVTOL2.png';
import eVTOL3 from '../Images/eVTOL3.png';
import eVTOL4 from '../Images/eVTOL4.png';
import eVTOL5 from '../Images/eVTOL5.png';
import eVTOL6 from '../Images/eVTOL6.png';
import eVTOL7 from '../Images/eVTOL7.png';
import eVTOL8 from '../Images/eVTOL8.png';
import MUI from '../Images/MUI.png';
import ESCAPE from '../Images/ESCAPE.png';
import Museum from '../Images/Museum.png';
import Sandbox from '../Images/Sandbox.png';

export const GlobalStyle = createGlobalStyle`


  body {
    background-color: black;
    color: white;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
  }

  #leftPanel {
    width: 40%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
  }

  #rightPanel {
    width: 60%;
    height: 100%;
    padding: 20px;
    overflow-y: auto;
  }
`;

const projectsData = [
  {
    name: "Active Systems Literature Review for the Retrofit of Industrial Buildings in Cold Climates",
    description:  [
        'I conducted a comprehensive Literature Review on Active System Retrofits for ReCONstruct, a multi-year joint initiative between McGill University and Hydro-Quebec developing turn-key solutions to enable deep energy retrofits of the Quebec Building Stock. I focused on evaluating the potential for active system retrofit in large-scale institutional and commercial buildings, particularly in the Montreal region. This entailed an in-depth study of different building types, taking into consideration factors such as age, location, and usage to discern the most effective energy efficiency measures. I synthesized a significant amount of data, including the findings of several systematic reviews and field studies.',
        'The research centered on cutting-edge solutions in the field of energy efficiency, such as Solar-Assisted Heat Pumps (SAHPs) and multi-function heat pumps, as well as practicalities and challenges associated with their implementation. Among the key findings were insights into the variance in performance and capacity of SAHPs under extreme weather conditions, the unique difficulties faced in integrating multi-function systems, and the discrepancies in methodologies adopted across the field. A significant portion of the project involved extracting key numerical data from the research material. This led to the identification of quantifiable benefits of various energy efficiency measures, such as the increased Coefficient of Performance (COP) of SAHPs compared to traditional systems, and the percentage increase in heat capacity and COP under extreme weather conditions.',
        'I also developed a comprehensive framework based on the literature review. This was designed to guide the decision-making process when considering energy retrofit measures for a variety of buildings. The framework provides a systematic approach, accounting for the specifics of building type, age, location, and usage, and prescribing the optimal retrofit measures accordingly.',
        'This project has significantly advanced my understanding of energy efficiency measures in the architectural field, particularly in the context of large-scale commercial and institutional buildings. The insights gained and the framework developed will be valuable tools in creating more sustainable and energy-efficient architectural solutions in the future.',
    ],
    img: HeatPumpProjectImage,
    buttonData: [{
        text: 'Read the full literature review',
        url: 'https://docs.google.com/document/u/1/d/e/2PACX-1vR501D7t3iPeLRUIro2Squkp2z7XDC7bx3Rn67WHJ68CAyXYqKDCPtmIpoE-NqNkjZ8uvcESwAXZzIy/pub',
    }],
    tags: ["Sustainability", "Technical Writing","Research"]
  },
  // ...more projects
  {
    name: "Automated Solar Shading Device with Integrated Controls for Deep-Energy Retrofits in Quebec (Capstone)",
    description: [
        'This project focused on designing and developing an innovative solar shading device aimed at optimizing the energy consumption of buildings. By controlling the amount of solar radiation entering the building, the device addresses the two largest energy consumers in structures – HVAC and lighting systems – thereby significantly enhancing energy efficiency. This Capstone project was undertaken in collaboration with the ReCONstruct Lab at McGill University and Hydro-Quebec.',
        'One of my main contributions to this project was setting up and running Building Energy Modelling simulations using EnergyPlus and SketchUp. This facilitated detailed performance validation and enabled the exploration of device effectiveness under varying real-world scenarios. It was configured to a modified archetype of the US Department of Energy (DOE) Small Office Building, adapted for very cold climates. The simulations were carried out with and without the overhang, focusing on the climate context of Montreal. The energy model testing results were promising, displaying a significant annual energy consumption reduction of 14.8%, translating into substantial cost savings.',
        'Future prospects for this project are immense, with a wide range of potential enhancements and explorations. This includes the re-running of energy modelling simulations on various building types and climate regions, investigation into the effect of window-to-wall ratio on energy savings, refinement of electrical integration, addressing wind load calculations and compliance with building codes and regulations, and ensuring the  ability to withstand varying weather conditions.',
    ],
    img: CapstoneImage,
    buttonData: [ // new buttons field
    {
      text: 'High Quality Poster',
      url: 'https://drive.google.com/file/d/1w2lhcPkVrwhmI-Co11dZXqgQrg9pvgwR/view?usp=sharing'
    },
    {
      text: 'Read the Full Report',
      url: 'https://docs.google.com/document/d/e/2PACX-1vSH632palDOQ-rLUqsSeKU4yE6n-9CUjCrJZ45EuQefBC_U6s9fRYS-xRQfA-f43JHJrlkRtbzxksUi/pub'
    },
    {
      text: 'See the Prototype in Action',
      url: 'https://drive.google.com/file/d/1ZWRrei1Fgy00WXTv2aoGR6M8PWBjVjoc/view?usp=drive_link'
    },
],
    tags: ["Building Energy Modelling (BEM)", "Computer Aided Design (CAD)","Mechanical","Sustainability"]
  },
  {
    name: "Investigating the effects of Extreme Climate Events in the Pacific NorthWest",
    description: "Extreme Climate Events such as heat waves, torrential flooding, severe snow storms and cyclones are all expressions of climate variability. Such events could not always be linked to climate change, but the link is becoming much more apparent with new research. The frequency and intensity of such events and the possibility of compounding events is projected to continue to increase in the coming years with trends projected to accelerate under specific greenhouse gas emissions scenarios. The Pacific NorthWest as a geographic region is particularly sensitive to such events occurring due to the climate systems that influence it with such extreme climate events already increasing in severity and frequency. This is already beginning to have devastating effects on the local infrastructure and resultantly the economy. The Coquihalla Highway, a vital lifeline for the transportation of goods that links the towns of Merritt and Hope, is examined as a case study and it’s risk assessment is reviewed in light of the failure of the highway in 2021 due to intense rainfall and flooding.",
    img: NorthWest,
    buttonData: [{
        text: 'Read the full Technical Report',
        url: 'https://docs.google.com/document/u/1/d/e/2PACX-1vQqbSUD6rr4bvb9PNJ1yZZi1kQdYdbSleNAG0_wD_Lfd9p-DUXV2MPxkSbSKyeYchiSxMkKFQ43IyOL/pub',
    }],
    tags: ["Sustainability", "Technical Writing","Research"]
  },
  {
    name: "UX Redesign of the Value Analysis Canada Website with Interactive Before/After Slider",
    description: ['The Value Analysis Canada Website Diagnostic and Redesign project was a comprehensive effort to upgrade the existing website and ensure its functionality, usability, and design exceeded or at least matched those of peer group websites. This initiative was divided into four core phases: Preparation, Analysis, Solution Development, and Reporting. In the Preparation phase, I performed a thorough investigation of competitive sites, set up analytics, and became intimately familiar with the website. In the Analysis phase, I conducted market research, performed front-end, content, and technical diagnostics as well as benchmarked the website against its peers.',
    'After garnering insights from the first two phases, the project moved to the Solution Development phase. Here, the most efficient strategies were outlined for content restructuring, User Interface (UI) modifications, and the technical execution of these changes. The focus was to utilize insights from the previous phases to craft a design that efficiently addressed all identified challenges, ranging from content layout to user interface.',
    'Thorough investigation led to a series of insightful recommendations for the Value Analysis Canada website. It was identified that it was crucial to resolve issues documented in the logbook and conduct a similar content review for the French section of the website. Additionally a restructuring of content and menu bar navigation as well as modifications to the front-end were suggested to resolve UI/UX issues. Furthermore, a revision of the page structure, standardization of site design, upgrading to BootStrap version 5, and addressing security issues were deemed necessary.',
    'Recognizing the importance of ongoing care for the website, a stress was placed on regular updates and maintenance. Quality control for content, including grammar and spelling checks, resolution standards for photos and videos, and regular content audits were proposed. To keep the website robust, user-friendly, and engaging, adherence to best practices in website design and regular monitoring of changing trends was strongly recommended.',

],
    img: VACmain,
    beforeAfterImages: { // New field to contain the before and after images
      before: VAC2,
      after: VAC1,
    },
    buttonData: [
        {
          text: 'Read the full Report',
          url: 'https://docs.google.com/document/d/e/2PACX-1vQe176feCZntJ3JbJGhTlT6O6HrbHhyxCa7N-5SOaJlSPG9FqaGy4TeH-AwxnQHcHLtZhb3nvn7xBhH/pub',
        },
        {
          text: 'Visit the Value Analysis Canada Website',
          url: 'https://www.valueanalysis.ca/index.php',
        },
      ],
    tags: ["User Experience (UX)", "Software"]
  },
  {
    name: "Indigenous Youth Sustainability Workshops at the Kahnawake Youth Center (KYC) (Video)",
    description: [
        'This project involved the conceptualization, planning, budgeting, and execution of two series of workshops dedicated to sustainability education for Indigenous youth (aged 14-18) at the Kahnawake Youth Center (KYC).',
        'Leading the content creation team, I developed accessible educational content grounded in themes such as Eco-Design, Life Cycle Thinking, and Systems Thinking. The goal was to make learning as engaging and as different from a traditional classroom as possible.',
        'One of the standout sessions was a detective case study workshop, designed to train students in research methods as well as the harmful effects of climate change by investigating the cause of the Lytton town fire. Expert interviews and prepared materials complemented the immersive activity. Another workshop introduced participants to the concept of failure as an essential part of the iterative design process. In this hands-on session, participants used playdough to model and remodel various designs, learning the value of trial and error in problem-solving. Another workshop focused on thinking in life cycles and systems. Participants were given access to an online tool that allowed them to build and manipulate their own systems, helping them visualize and understand the profound effects of even small changes within a system. The final workshop focused on the history of Bridge-building within the Indigenous community had the students put theory into practice, constructing spaghetti bridges to solidify their learning.',
        'Along with the content creation, the project involved coordinating a team of 3-4 volunteers per workshop, ensuring effective delivery and support for the participants. Community partnerships were fostered for seamless workshop delivery and to create a profound impact through youth outreach. The project received overwhelmingly positive feedback from the community, participants, their parents, and the KYC staff. The interactive, hands-on approach to sustainability education resonated strongly with the participants, indicating that such educational methods could be a powerful tool for engaging youth in critical environmental issues.',
    ],
    img: Sandbox,
    tags: ["Sustainability","Education","Project Management"]
  },
  {
    name: "Award Winning CAD of an Automated Fast Food Restaurant (CAD|MADNESS 1st Place)",
    description: [
        'Please use the buttons to the right and left to go through the slides and see the full project.',
        'This Project was completed as part of the CAD|MADNESS 2020 competition, an annual competition by the McGill Association of Mechanical Engineers (MAME) with this edition being sponsored by Vention. The challenge was to design an automated fast food restaurant using CAD software while integrating vention components for automation. The project was placed first in the best overall senior design category. The project was completed using NX and Solidworks.',
    ],
    img: CMain,
    tags: ["Mechanical", "Computer Aided Design (CAD)"]
  },
  {
    name: "Personal Portfolio Website (This Website!)",
    description: [
        'This project represents a personal portfolio website, designed and developed from scratch, to showcase my range of projects and professional experiences. The website incorporates a modern, user-friendly interface, characterized by intuitive navigation and interactive features, to provide visitors with easy access to information and enhance user engagement.',
        'The home page provides a comprehensive introduction to my professional background and skills as well as provides navigation to other sections of the site, ensuring smooth user flow. The CV page incorporates a detailed presentation of my professional history and accomplishments, laid out in an easy-to-read format. The projects page showcases my work portfolio, featuring a wide range of projects with filters for easy sorting and navigation. Each project is presented with a description, tags for the involved skills, and a lightbox feature for detailed view. The lightboxes show expanded information, including detailed project descriptions, carousels of images, embedded videos, and external links, presenting a comprehensive view of the work done. A user-friendly navigation feature that sticks to the top of the page as you scroll, providing continuous and easy access to key sections of the site.',
        'The design approach focused on a minimalist aesthetic, utilizing a monochromatic color scheme and simple typography to ensure readability and visual appeal. Responsiveness was prioritized to ensure optimal viewing across multiple device types, from desktops to mobile phones.',
        'The site was developed using React.js, utilizing hooks and functional components to manage state and handle user interaction. Material-UI was used for the front-end design, providing a collection of responsive, customizable, and accessible UI components. The project data is structured in a modular format, allowing easy updates and scalability for future projects.',
        'This website demonstrates my capabilities in full-stack web development, UX design, and project management, embodying my dedication to creating efficient, user-centered digital solutions.',
    ],
    img: MUI,

    tags: ["User Experience (UX)", "Software"]
  },
  {
    name: "Life Cycle Assessment (LCA) of a Penny Farthing Bicycle",
    description: [
        'This project was an intensive investigation into the environmental impacts of two different materials used for manufacturing bicycle frames. It involved conducting a comprehensive LCA in accordance with ISO 14044 and ISO/TR 14049 standards, utilizing OpenLCA 1.10.3 software along with the Ecoinvent 3.1 database. The assessment studied and compared the environmental impact of aluminum and the selected composite (Carbon Fiber Reinforced Polymer - CFRP) bicycle frames, focusing on four categories: Climate Change, Ecosystem Quality, Human Health, and Resources.',
        'The results provided critical insights into the sustainability performance and environmental hotspots associated with both aluminum and CFRP. Despite the higher impacts of aluminum on Ecosystem Quality and Human Health, it emerged as the superior choice due to its infinite recyclability without property degradation. Although CFRP outperformed aluminum in some areas, its overall environmental impact was greater, primarily due to its epoxy production derived mainly from the oil industry and the difficulties in recycling CFRP without altering its material properties.',
    ],
    buttonData: [{
        text: 'Read the full Technical Report',
        url: 'https://docs.google.com/document/d/e/2PACX-1vRSCCUat3yY--8pcFFOvlkf7eDRy1fufOft10vZkvV6tzeNZEsxJ_Fq5LD6lOddYA/pub',
    }],
    img: LCA,
    tags: ["Sustainability", "Research","Technical Writing", "Life Cycle Assessment (LCA)"]
  },
  {
    name: "Social Networking Website for McGill Engineering (Video Demo)",
    description: [
        'The project created a website for the Engineering Socials Committee (ESC) at McGill University. This project aimed to create a digital platform that would streamline organizing and ticketing for social events while fostering a sense of community among the undergraduate engineering students. Utilizing SpringBoot application using Gradle, PostgreSQL, Vuetify, Javascript, HTML, Github, and VSCode, the website was designed and developed using agile methods for flexible and adaptive execution.',
        'A user account management system was developed, enabling users to create, edit, and delete their accounts. With their accounts, users were given the opportunity to participate in various activities, such as registering for events, interacting with other users through posts and comments, and even reselling their event tickets. The website was also designed to allow users to view all attendees for an event as it was for a small tightly-knit community.',
        'The design approach focused on a minimalist aesthetic, utilizing a monochromatic color scheme and simple typography to ensure readability and visual appeal. Responsiveness was prioritized to ensure optimal viewing across multiple device types, from desktops to mobile phones.',
        'Admin accounts were created with comprehensive controls to manage content and users on the website. They could edit or delete any post or user profile, upload photos and descriptions to the event galleries, post new events, and even cancel events if necessary. Moreover, the website was equipped with a social platform that allowed users to post comments and mark them as inappropriate if necessary. This feature worked hand-in-hand with a notification system that alerted the committee members of any flagged posts, empowering them to take swift action by removing posts or blocking users as needed.',
        'Emphasis was also placed on data persistence, ensuring information posted on the website was securely stored in a robust database. As a result of this collaborative effort, the team successfully delivered a highly functional and user-friendly website that catered effectively to the needs of the ESC and its community.',
    ],
    buttonData: [{
        text: 'Github Repository',
        url: 'https://github.com/rarchambault/ESCAPE',
    }],
    img: ESCAPE,
    tags: ["Software",]
  },
  {
    name: "Museum Scheduling and Inventory Management System",
    description: [
        'A comprehensive website was developed for a small-town museum. The museum, featuring ten rooms for artwork display and a large storage space, had a unique set of needs. Five rooms were larger, accommodating up to 300 artworks, while the remaining five could fit 200 pieces. The vast storage room could hold an almost limitless number of artworks.',
        'Primary responsibilities encompassed the creation of requirements, the drafting of UML diagrams, and the formulation of persistence tests for the system. Differing functionality was implemented for various user type. The owner, with their dedicated user account, could manage elements such as opening hours, employee schedules, visitor and rental fees, as well as employment decisions. Meanwhile, employee accounts facilitated the arrangement of artworks across various rooms and storage spaces. Lastly, visitor accounts allowed users to purchase museum passes, browse artwork, and request loans for certain items.',
        'The project was built using SpringBoot, Gradle, PostgreSQL, Vuetify, Javascript, HTML, Github, and VSCode. Unfortunately the project repository is not available for public viewing.',
    ],
    img: Museum,
    tags: ["Software",]
  },
  {
    name: "Mechanical Design of a Fuel System for a BAJA SAE Vehicle",
    description: [
        'As the subsystem lead for the Fuel System, it was my responsibility to design a fuel system for the McGill Baja SAE Vehicle. As the SAE rules and regulations had just been updated to feature an entirely new gastank system, I was tasked with creating a novel system to account for the change. My primary focus on this project was to keep the weight of the system to a minimum. ',
        'Two mounting methods were investigated; one using square tubing and one using C-Type brackets. Both solutions were created using CAD and it was found that using C-Type brackets would be 1.4 lbs lighter. These would also deemed easier to manufacture via laser-cutting and bending. Additionally, the splash guard as well as cowling were also designed along with their mounting brackets to ensure that the fuel system was protected from the elements. ',
        'The CAD and FEA were done using NX, with AutoCAD being used to draft the manufacturing drawings for the brackets.',
    ],
    img: MB20,
    tags: ["Mechanical", "Computer Aided Design (CAD)","Finite Element Analysis (FEA)"]
  },
  {
    name: "CAD of an electric Vertical Take-Off Landing Aircraft",
    description: [
        'Please use the arrows to the right and left to go through the images and see the full project.',
        'This project was done during a class project on fluid dynamics. The airfoils have been designed to the exact specification specified by the team. The CAD was done using NX.',
    ],
    img: eVTOL1,
    tags: ["Computer Aided Design (CAD)"]
  },
];


const getAllTags = (projects) => {
    const tags = projects.reduce((allTags, project) => allTags.concat(project.tags), []);
    return [...new Set(tags)];
  };
  

// Component Styling

const Tag = styled.h1`
  cursor: pointer;
  text-decoration: underline;
  transition: transform 0.2s;
  opacity: ${props => props.selected ? 1 : 0.75};


  &:hover {
    transform: scale(1.05);
  }
`;


const ProjectsPage = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2em;
  width: 67%;
  padding: 2em;
  background-color: black;
`;

const TagsContainer = styled.div`
  width: 33%;
  padding: 2em;
  background-color: black;
  color: white;
`;

const ProjectCard = styled.div`
  flex: 0 0 calc(25% - 2em);
  height: 300px;
  margin: 1em;
  background-color: white;
  border-radius: 20px;
  padding: 1.5em;
  text-align: center;
  cursor: pointer;
  color: black;

  @media (min-width: 600px) {
    flex: 0 0 calc(20% - 2em);
  }

  &:hover {
    transform: scale(1.05);
    transition: transform .2s;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 60%;
  border-radius: 15px;
`;

const AppContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: black;
  color: white;
`;

const LightboxBackground = styled.div`
  background-color: rgba(0,0,0,0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LightboxContent = styled.div`
  background-color: white;
  width: 80%;
  height: 80%;
  overflow-y: auto;
  border-radius: 15px;
  padding: 2em;
`;

const Projects = () => {
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedTag, setSelectedTag] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const allTags = ['All Projects', ...getAllTags(projectsData)];

  
    const onProjectClick = (project) => {
        setSelectedProject({
          ...project,
          title: project.name,  // use name instead of title
        });
      };
  
    function onLightboxClick(e) {
      if (e.target === e.currentTarget) {
        setSelectedProject(null);
      }
    }


    
    const filterProjects = (project) => {
        if (selectedTag === 'All Projects') {
            return true;
        }
    
        if (selectedTag !== '' && !project.tags.includes(selectedTag)) {
            return false;
        }
        
        if (searchTerm !== '' && !project.name.includes(searchTerm) && !project.description.includes(searchTerm)) {
            return false;
        }
    
        return true;
    };
    
    
      const projectNames = projectsData.map(project => project.name);
      const onTagClick = (tag) => {
        setSelectedTag(tag);
      };

      return (
        <AppContainer>
          <GlobalStyle />
          <TagsContainer>
  {allTags.map((tag, i) => (
    <Tag 
      key={i} 
      selected={selectedTag === tag} 
      onClick={() => onTagClick(tag)}
    >
      {tag}
    </Tag>
  ))}
</TagsContainer>
          <ProjectsPage>
            {projectsData.filter(filterProjects).map((project, i) => (
              <ProjectCard key={i} onClick={() => onProjectClick(project)}>
                <h3>{project.name}</h3>
                <CardImage src={project.img} alt={project.name} />
              </ProjectCard>
            ))}
          </ProjectsPage>
      {selectedProject && (
  <LightboxBackground onClick={onLightboxClick}>
  <LightboxContent style={{ color: 'black' }}> 
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ marginBottom: '0.5em' }}>{selectedProject.title}</h1>
      
      {selectedProject.title === 'UX Redesign of the Value Analysis Canada Website with Interactive Before/After Slider' ? (
        <div style={{ maxWidth: '450px', margin: '0 auto' }}>
          <ReactBeforeSliderComponent
            firstImage={{ imageUrl: selectedProject.beforeAfterImages.before }}
            secondImage={{ imageUrl: selectedProject.beforeAfterImages.after }}
          />
        </div>
      ) 
      : (selectedProject.title === 'Social Networking Website for McGill Engineering (Video Demo)' ? (
        <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
          <iframe src="https://drive.google.com/file/d/1F626EYChyPrdST8gk4pWniHGUWO1BPGP/preview" width="700" height="480" allow="autoplay"></iframe>
        </div>
      ) : (selectedProject.title === 'Indigenous Youth Sustainability Workshops at the Kahnawake Youth Center (KYC) (Video)' ? (
        <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
          <iframe width="1000" height="695" src="https://www.youtube.com/embed/pON63T-Vj5k" title="E-IDEA: Action xDesign Year End Reflections (2021-2022)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      )  : selectedProject.title === 'Award Winning CAD of an Automated Fast Food Restaurant (CAD|MADNESS 1st Place)' ? (
          <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
        <Carousel>
          <div>
            <img src = {CM1} />
          </div>
          <div>
            <img src= {CM2} />
          </div>
          <div>
            <img src= {CM3} />
          </div>
          <div>
            <img src= {CM4} />
          </div>
          <div>
            <img src= {CM5} />
          </div>
          <div>
            <img src= {CM6} />
          </div>
          <div>
            <img src= {CM7} />
          </div>
        </Carousel>
        </div>
      ) : selectedProject.title === 'CAD of an electric Vertical Take-Off Landing Aircraft' ? (
        <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
          <Carousel>
            <div>
              <img src={eVTOL1} />
            </div>
            <div>
              <img src={eVTOL2} />
            </div>
            <div>
              <img src={eVTOL3} />
            </div>
            <div>
              <img src={eVTOL4} />
            </div>
            <div>
              <img src={eVTOL5} />
            </div>
            <div>
              <img src={eVTOL6} />
            </div>
            <div>
              <img src={eVTOL7} />
            </div>
            <div>
              <img src={eVTOL8} />
            </div>

          </Carousel>
        </div>
      ) : selectedProject.title === 'Mechanical Design of a Fuel System for a BAJA SAE Vehicle' ? (
        <div style={{ maxWidth: '500px', margin: '0 auto' }}>
          <Carousel>
            <div>
              <img src={MB20CAD} />
            </div>
            <div>
              <img src={MB20} />
            </div>

          </Carousel>
        </div>
      ): (
        <img
          height="500"
          src={selectedProject.img} 
          alt={selectedProject.title} 
          style={{ marginBottom: '0.5em' }}
        />
      )))}
    </div>
    {Array.isArray(selectedProject.description) 
      ? selectedProject.description.map((paragraph, index) => (
          <p style={{ textAlign: 'left' }} key={index}>{paragraph}</p>
        ))
      : <p style={{ textAlign: 'left' }}>{selectedProject.description}</p>
    }
    {selectedProject.buttonData && selectedProject.buttonData.map((button, index) => 
      <button 
        key={index}
        style={{
          backgroundColor: 'black', 
          color: 'white', 
          padding: '1em', 
          borderRadius: '20px',
          border: 'none', 
          cursor: 'pointer',
          marginTop: '1em',
          marginRight: '1em',
        }}
        onClick={() => window.open(button.url, '_blank')}
      >
        {button.text}
      </button>
    )}
        </LightboxContent>
      </LightboxBackground>
    )}
  </AppContainer>
);
}
export default Projects;