import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Container,Grid,Paper, Card,CardMedia } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeatPumpProjectImage from '../Images/Heatpumps.png';
import CapstoneImage from '../Images/Capstone.png';
import VACmain from '../Images/VACmain.png';
import VAC1 from '../Images/VAC1.png';
import VAC2 from '../Images/VAC2.png';
import CMain from '../Images/CMain.png';
import CM1 from '../Images/CM1.png';
import CM2 from '../Images/CM2.png';
import CM3 from '../Images/CM3.png';
import CM4 from '../Images/CM4.png';
import CM5 from '../Images/CM5.png';
import CM6 from '../Images/CM6.png';
import CM7 from '../Images/CM7.png';



const LightboxBackground = styled.div`
  background-color: rgba(0,0,0,0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LightboxContent = styled.div`
  background-color: white;
  width: 80%;
  height: 80%;
  overflow-y: auto;
  border-radius: 15px;
  padding: 2em;
`;

function CVPage() {

    const [selectedProject, setSelectedProject] = React.useState(null);

function onProjectClick(project) {
  setSelectedProject(project);
}

function onLightboxClick(e) {
  if (e.target === e.currentTarget) {
    setSelectedProject(null);
  }
}

    const awardsData = [
        {
            name: "Most Improved Committee",
            year: "2023",
            description: "Presented by the Engineering Undergraduate Society to the Engineering Socials Committee for exceptional growth and development."
          },
        {
            name: "Volunteer of the Year",
            year: "2020",
            description: "Presented by the McGill Association of Mechanical Engineers (MAME) for extraordinary contributions to the departmental society."
          },
        {
          name: "CAD|MADNESS First Place",
          year: "2019",
          description: "Won CAD|MADNESS cad-athon placing first in the best overall design category, tackling the challenge of automating a fast-food restaurant and presenting a CAD-based solution."
        }
       
      ]
      

    const skillsData = [
        {
          title: 'Mechanical',
          skills: [
            'Computer Aided Design: NX, Solidworks, AutoCAD, Revit, SketchUp',
            'Finite Element Analysis: NX, Abaqus',
            'EcoDesign and Life Cycle Assessment: OpenLCA',
            'Building Energy Modelling: EnergyPlus, OpenStudio',
          ],
        },
        {
          title: 'Software',
          skills: [
            'Languages: Java, JavaScript, OCaml, C, G-Code, Matlab',
            'Web Dev: HTML, CSS, JavaScript, React, Vue.js, Vuetify, Material UI',
            'Backend: Spring Boot, PostgreSQL',
            'UX Design'
          ],
        },
        {
          title: 'Human',
          skills: [
            'Project Management, Budgeting, Leadership',
            'Communication: Technical Writing, Public Speaking, Presentation Skills',
            'Languages: English (Native), Urdu/Hindi (Native), Arabic (Intermediate), French (Beginner)',
          ],
        },
      ];
      

      const selectedProjectsData = [
        {
          title: 'Automated Solar Shading Device with Integrated Controls for Deep-Energy Retrofits in Quebec (Capstone)',
          description: [
            'This project focused on designing and developing an innovative solar shading device aimed at optimizing the energy consumption of buildings. By controlling the amount of solar radiation entering the building, the device addresses the two largest energy consumers in structures – HVAC and lighting systems – thereby significantly enhancing energy efficiency. This Capstone project was undertaken in collaboration with the ReCONstruct Lab at McGill University and Hydro-Quebec.',
            'One of my main contributions to this project was setting up and running Building Energy Modelling simulations using EnergyPlus and SketchUp. This facilitated detailed performance validation and enabled the exploration of device effectiveness under varying real-world scenarios. It was configured to a modified archetype of the US Department of Energy (DOE) Small Office Building, adapted for very cold climates. The simulations were carried out with and without the overhang, focusing on the climate context of Montreal. The energy model testing results were promising, displaying a significant annual energy consumption reduction of 14.8%, translating into substantial cost savings.',
            'Future prospects for this project are immense, with a wide range of potential enhancements and explorations. This includes the re-running of energy modelling simulations on various building types and climate regions, investigation into the effect of window-to-wall ratio on energy savings, refinement of electrical integration, addressing wind load calculations and compliance with building codes and regulations, and ensuring the  ability to withstand varying weather conditions.',
        ],
          imageUrl: CapstoneImage,
          buttonData: [ // new buttons field
    {
      text: 'High Quality Poster',
      url: 'https://drive.google.com/file/d/1w2lhcPkVrwhmI-Co11dZXqgQrg9pvgwR/view?usp=sharing'
    },
    {
      text: 'Read the Full Report',
      url: 'https://docs.google.com/document/d/e/2PACX-1vSH632palDOQ-rLUqsSeKU4yE6n-9CUjCrJZ45EuQefBC_U6s9fRYS-xRQfA-f43JHJrlkRtbzxksUi/pub'
    },
    {
      text: 'See the Prototype in Action',
      url: 'https://drive.google.com/file/d/1ZWRrei1Fgy00WXTv2aoGR6M8PWBjVjoc/view?usp=drive_link'
    },
  ]
        },
        {
          title: 'UX Redesign of the Value Analysis Canada Website with Interactive Before/After Slider',
          description: ['The Value Analysis Canada Website Diagnostic and Redesign project was a comprehensive effort to upgrade the existing website and ensure its functionality, usability, and design exceeded or at least matched those of peer group websites. This initiative was divided into four core phases: Preparation, Analysis, Solution Development, and Reporting. In the Preparation phase, I performed a thorough investigation of competitive sites, set up analytics, and became intimately familiar with the website. In the Analysis phase, I conducted market research, performed front-end, content, and technical diagnostics as well as benchmarked the website against its peers.',
                        'After garnering insights from the first two phases, the project moved to the Solution Development phase. Here, the most efficient strategies were outlined for content restructuring, User Interface (UI) modifications, and the technical execution of these changes. The focus was to utilize insights from the previous phases to craft a design that efficiently addressed all identified challenges, ranging from content layout to user interface.',
                        'Thorough investigation led to a series of insightful recommendations for the Value Analysis Canada website. It was identified that it was crucial to resolve issues documented in the logbook and conduct a similar content review for the French section of the website. Additionally a restructuring of content and menu bar navigation as well as modifications to the front-end were suggested to resolve UI/UX issues. Furthermore, a revision of the page structure, standardization of site design, upgrading to BootStrap version 5, and addressing security issues were deemed necessary.',
                        'Recognizing the importance of ongoing care for the website, a stress was placed on regular updates and maintenance. Quality control for content, including grammar and spelling checks, resolution standards for photos and videos, and regular content audits were proposed. To keep the website robust, user-friendly, and engaging, adherence to best practices in website design and regular monitoring of changing trends was strongly recommended.',

        ],
        imageUrl: VACmain,
        beforeAfterImages: { // New field to contain the before and after images
          before: VAC2,
          after: VAC1,
        },
        buttonData: [
            {
              text: 'Read the full Report',
              url: 'https://docs.google.com/document/d/e/2PACX-1vQe176feCZntJ3JbJGhTlT6O6HrbHhyxCa7N-5SOaJlSPG9FqaGy4TeH-AwxnQHcHLtZhb3nvn7xBhH/pub',
            },
            {
              text: 'Visit the Value Analysis Canada Website',
              url: 'https://www.valueanalysis.ca/index.php',
            },
          ],
        },
        {
            title: 'Active Systems Literature Review for the Retrofit of Industrial Buildings in Cold Climates',
            description: [
                'I conducted a comprehensive Literature Review on Active System Retrofits conducted for ReCONstruct, a multi-year joint initiative between McGill University and Hydro-Quebec developing turn-key solutions to enable deep energy retrofits of the Quebec Building Stock. I focused on evaluating the potential for active system retrofit in large-scale institutional and commercial buildings, particularly in the Montreal region. This entailed an in-depth study of different building types, taking into consideration factors such as age, location, and usage to discern the most effective energy efficiency measures. I synthesized a significant amount of data, including the findings of several systematic reviews and field studies.',
                'The research centered on cutting-edge solutions in the field of energy efficiency, such as Solar-Assisted Heat Pumps (SAHPs) and multi-function heat pumps, as well as practicalities and challenges associated with their implementation. Among the key findings were insights into the variance in performance and capacity of SAHPs under extreme weather conditions, the unique difficulties faced in integrating multi-function systems, and the discrepancies in methodologies adopted across the field. A significant portion of the project involved extracting key numerical data from the research material. This led to the identification of quantifiable benefits of various energy efficiency measures, such as the increased Coefficient of Performance (COP) of SAHPs compared to traditional systems, and the percentage increase in heat capacity and COP under extreme weather conditions.',
                'I also developed a comprehensive framework based on the literature review. This was designed to guide the decision-making process when considering energy retrofit measures for a variety of buildings. The framework provides a systematic approach, accounting for the specifics of building type, age, location, and usage, and prescribing the optimal retrofit measures accordingly.',
                'This project has significantly advanced my understanding of energy efficiency measures in the architectural field, particularly in the context of large-scale commercial and institutional buildings. The insights gained and the framework developed will be valuable tools in creating more sustainable and energy-efficient architectural solutions in the future.',
            ],
            imageUrl: HeatPumpProjectImage,
            buttonData: [{
                text: 'Read the full literature review',
                url: 'https://docs.google.com/document/u/1/d/e/2PACX-1vR501D7t3iPeLRUIro2Squkp2z7XDC7bx3Rn67WHJ68CAyXYqKDCPtmIpoE-NqNkjZ8uvcESwAXZzIy/pub',
            }],
        },
        
        {
          title: 'Award Winning CAD of an Automated Fast Food Restaurant (CAD|MADNESS 1st Place)',
          description: [
            'Please use the buttons to the right and left to go through the slides and see the full project.',
            'This Project was completed as part of the CAD|MADNESS 2020 competition, an annual competition by the McGill Association of Mechanical Engineers (MAME) with this edition being sponsored by Vention. The challenge was to design an automated fast food restaurant using CAD software while integrating vention components for automation. The project placed first in the best overall senior design category. The project was completed using NX and Solidworks.',
        ],
          imageUrl: CMain,
        },
    ];
    
      

    const extraCurricularData = [
        {
            role: 'Chair, Engineering Socials Committee',
            organization: 'Engineering Undergraduate Society',
            dates: '2022 –2023',
            points: [
                'Started a committee to host social events for engineering students after the COVID-19 pandemic, developing a recruitment, branding, and marketing strategy.',
                'Budgeted and led the committee in planning and executing events making CAD 20,000 in Revenue for the Engineering Undergraduate Society.'
            ]
        },
        {
            role: 'Design Team Director',
            organization: 'Engineering Undergraduate Society',
            dates: '2019 - 2020',
            points: [
                'Served as the primary liaison for 10 Engineering Design Teams with the Faculty of Engineering and external organizations, orchestrating administrative affairs and ensuring workshop safety.'
            ]
        },
        {
            role: 'Fuel System Team Lead (2019 – 2020)',
            organization: 'McGill Baja Racing',
            dates: '2019–2020',
            points: [
                'Designed and developed a novel system to comply with Baja SAE regulations using CAD (NX) and Finite Element Analysis (NX) to validate the design.',
                'Drafted Technical Drawings (AutoCAD) and established relationships with part manufacturers and suppliers.',
                'Led the assembly of the system, confirmed its performance through rigorous testing, and ensured strict compliance with all BAJA SAE regulations and requirements.'
            ]
        },
        {
            role: 'Shifter Team Lead (2018 -2019)',
            organization: 'McGill Baja Racing',
            dates: '2018–2019',
            points: [
                'Designed and developed an improved system to comply with Baja SAE regulations using CAD (NX) and Finite Element Analysis (NX) to validate the design.',
                'Drafted Technical Drawings (AutoCAD) and established relationships with part manufacturers and suppliers.',
                'Led the assembly of the system, confirmed its performance through rigorous testing, and ensured strict compliance with all BAJA SAE regulations and requirements.'
            ]
        }
    ];
    

    const educationData = [
        {
          university: 'McGill University',
          degree: 'Bachelor of Engineering; Major Mechanical Engineering, Minor Software Engineering',
          years: '2018 - 2023',
          description: 'Key Courses: Eco-Design & Life Cycle Assessment (LCA), Strategies for Sustainability [Management], Communication in Engineering, Mechanical Vibrations, System Dynamics and Controls, Value Engineering, Model-Based Programming, Programing Languages and Paradigms, Intensive Beginners French [Arts], Contemporary Moral Issues [Arts], Social Life of AI [Communication] '
        },
        // You can add more education entries here.
      ];

    const workExperienceData = [
        {
          jobTitle: 'Undergraduate Researcher',
          company: 'Reconstruct Lab, Hydro-Québec & McGill University',
          dates: 'May 2022 – April 2023',
          points: [
            'Performed Building Energy Modelling and Carbon Emissions Analysis on Retrofit Candidate Buildings using EnergyPlus and Revit.',
            'Conducted a comprehensive literature review analyzing advanced active system retrofit potential, focusing on multi-functional systems for institutional buildings in cold climates.',
            'Facillitated EDI focus-groups on the effects of retrofits on marginalized communities, creating documentation on primary socio-economic concerns.'
          ],
      
        },
          {
            jobTitle: 'UX Redesign Lead',
            company: 'Value Analysis Canada',
            dates: 'May 2022 – August 2022',
            points: [
                'Led a comprehensive website redesign, developing a detailed project plan, conducting in-depth diagnostics, implementing Google Analytics, and performing a competitive market analysis.',
                'Presented findings and updates to the Board of Directors, providing detailed reports and recommendations while ensuring efficient communication of necessary changes to the programming team.'
              ]
          
          },
          {
            jobTitle: 'Program Manager',
            company: 'Youth Action and Outreach Initiative, McGill University',
            dates: 'August 2021 – April 2022',
            points: [
                'Conceptualized, budgeted, planned, and executed two series of workshops to indigenous youth (aged 14-18) at the Kahnawake Youth Center (KYC).',
                'Led the workshop content creation team in creating accessible educational content around sustainablity related themes such as Eco-Design, Life Cycle Thinking and Systems Thinking.',
                'Efficiently coordinated volunteer teams and fostered community partnerships for seamless workshop delivery and impactful youth outreach.'
              ]
          
          },
          {
            jobTitle: 'Research Associate',
            company: 'How To Change The World ',
            dates: 'July 2020 – April 2021',
            points: [
                'Developed sustainability focused educational content for workshops as well as transitioned the in-person conference to a smooth online format amidst a global pandemic. '
              ]
          
          },
          {
            jobTitle: 'Office Assistant',
            company: 'Trottier Institute for Sustainability in Engineering and Design (TISED)',
            dates: 'September 2018 – April 2020',
            points: [
                'Contributed to a successful proposal for a masters program in Sustainability in Engineering and Design to be taught at McGill University.',
                'Efficiently planned, organized and budgeted for workshops, seminars and outreach events.'
              ]
          
          },
          {
            jobTitle: 'Technical Communication Assistant',
            company: 'Clinicals Lab, Ingram School Of Nursing',
            dates: 'April 2019 – August 2019',
            points: [
                'Authored, updated, and structured comprehensive user guides, datasheets, and technical documents for advanced hardware and software medical systems.',
                'Tailored content to suit the target audience of nursing staff and technicians providing presentations and demonstrations of features.',
                'Collaborated closely with subject matter experts to gather and verify technical information, ensuring the accuracy and relevance of the created documentation.'
              ]
          
          },
      ];
      

  return (
    <Box 
      style={{
        backgroundColor: 'white',
        color: 'black',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlign: 'center',
        paddingTop: '0em', // Adjust the top padding value as desired
        paddingBottom: '0em'
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1em',
          marginTop: '1em'
        }}
      >
        <Typography variant="h2" style={{ marginRight: '1em' }}>Murad Gohar</Typography>
      </Box>
      <Typography variant="body1" style={{ marginTop: '0em' }}>
        Welcome to my CV Page. Clicking on the sections below will expand them to show more details.
      </Typography>
      <Typography variant="body1" style={{ marginTop: '0em' }}>
  You can also find a pdf version of my <a 
     href="/my_cv.pdf" // Here is where you put the path to your PDF file. Adjust the name of the PDF file as necessary.
     target="_blank" 
     rel="noreferrer noopener" 
     style={{ color: 'inherit', textDecoration: 'underline' }}>
    CV here
  </a>. 
</Typography>
      {/* Work Experience Section */}
      <Container 
        style={{
          marginTop: '1em',
          backgroundColor: 'black',
          color: 'white',
          borderRadius: '10px',
          maxWidth: '80%', // Adjust for wider container
          transition: 'all 0.5s ease',
          '&:hover': {
            transform: 'scale(1.05)'
          }
        }}
      >
        <Accordion style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: '0em 0em' }}
          >
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Work Experience</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px' }}>
            {/* Work Experience Details */}
            {/* Each work experience can be a new Accordion component */}
            {workExperienceData.map((workExperience, index) => (
              <Accordion key={index} style={{ marginTop: '0.2em', backgroundColor: 'white', color: 'black', borderRadius: '10px' }}>
                <AccordionSummary
  expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
  aria-controls={`panel${index+2}-content`}
  id={`panel${index+2}-header`}
  style={{ padding: '0em 0.5em' }}
>
  <Grid container justifyContent="space-between" alignItems="center">
    <Grid item xs={9}>
      <Grid container direction="row" alignItems="center">
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>{workExperience.jobTitle}</Typography>
        <Typography variant="body1">&nbsp;- {workExperience.company}</Typography>
      </Grid>
    </Grid>
    <Grid item xs={3}>
      <Typography variant="body1" align="right">{workExperience.dates}</Typography>
    </Grid>
  </Grid>
</AccordionSummary>
                <AccordionDetails style={{ backgroundColor: 'white', color: 'black', borderRadius: '10px'}}>
  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
    {workExperience.points.map((point, index) => (
      <Typography key={index} style={{ marginBottom: '0em', textAlign: 'left' }}>
        • {point}
      </Typography>
    ))}
  </div>
</AccordionDetails>

              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
        
      </Container>
      {/* Rest of your CV content here */}
      {/* Education Section */}
      <Container 
  style={{
    marginTop: '0.2em',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '10px',
    maxWidth: '80%',
    transition: 'all 0.5s ease',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  }}
>
  <Accordion style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px' }}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
      aria-controls="panel1b-content"
      id="panel1b-header"
      style={{ padding: '0em 0em' }}
    >
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'left' }}>{educationData[0].university}</Typography>
          <Typography variant="body1" style={{ textAlign: 'left' }}>{educationData[0].degree}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" align="right">{educationData[0].years}</Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
    <AccordionDetails style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px' }}>
      <Typography>{educationData[0].description}</Typography>
    </AccordionDetails>
  </Accordion>
</Container>
<Container style={{ maxWidth: '83%', margin: '0.2em auto' }}>
  <Paper sx={{ backgroundColor: 'black', borderRadius: '10px', padding: '1em' }}>
    <Typography variant="h5" align="center" sx={{ color: 'white', marginBottom: '1em' }}>
      Skills
    </Typography>
    <Grid container spacing={3}>
      {skillsData.map((skill, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Paper
            sx={{
              backgroundColor: 'white',
              borderRadius: '10px',
              minHeight: '175px',
              padding: '1em',
              transition: '0.3s',
              '&:hover': {
                transform: 'translateY(-10px)',
              },
            }}
          >
            <Typography variant="h6" style={{ fontWeight: 'bold', color: 'black', marginBottom: '0em' }}>
              {skill.title}
            </Typography>
            {skill.skills.map((s, index) => (
              <Typography key={index} variant="body1" style={{ color: 'black', marginBottom: '0em' }}>
                {s}
              </Typography>
            ))}
          </Paper>
        </Grid>
      ))}
    </Grid>
  </Paper>
</Container>
<Container 
  style={{
    marginTop: '0.2em',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '10px',
    maxWidth: '80%',
    transition: 'all 0.5s ease',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  }}
>
  <Accordion style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px' }}>
    <AccordionSummary
  expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
  aria-controls="panel1c-content"
  id="panel1c-header"
  style={{ padding: '0em 0em' }}
>
  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Extra-Curricular Experience</Typography>
</AccordionSummary>
<AccordionDetails style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px' }}>
  {/* Extra-Curricular Experience Details */}
  {extraCurricularData.map((extraCurricular, index) => (
    <Accordion key={index} style={{ marginTop: '0.2em', backgroundColor: 'white', color: 'black', borderRadius: '10px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
        aria-controls={`panel${index+3}-content`}
        id={`panel${index+3}-header`}
        style={{ padding: '0em 0.5em' }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={9}>
            <Grid container direction="row" alignItems="center">
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>{extraCurricular.role}</Typography>
              <Typography variant="body1">&nbsp;- {extraCurricular.organization}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" align="right">{extraCurricular.dates}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ backgroundColor: 'white', color: 'black', borderRadius: '10px' }}>
  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
    {extraCurricular.points.map((point, index) => (
      <Typography key={index} style={{ marginBottom: '0.2em' }}>
        • {point}
      </Typography>
    ))}
  </div>
</AccordionDetails>
    </Accordion>
  ))}
</AccordionDetails>
</Accordion>
</Container>
<Container 
  style={{
    marginTop: '0.2em',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '10px',
    maxWidth: '80%',
    padding: '0.2em',
    
  }}
>
<Accordion style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px', margin: '0em' }}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
    aria-controls="panel1d-content"
    id="panel1d-header"
    style={{ padding: '0em 1em' }}
  >
    <Typography variant="h6" style={{ fontWeight: 'bold' }}>Selected Projects</Typography>
  </AccordionSummary>
  <AccordionDetails style={{ 
    backgroundColor: 'black', 
    color: 'white', 
    borderRadius: '10px', 
  }}>
    <Typography variant="subtitle1" style={{ marginBottom: '0em' }}>
      Click on the projects to see more about them. Explore these as well as all my other projects on my{' '}
      <a href="/projects" style={{color: 'white', textDecoration: 'underline'}}>projects page</a>.
    </Typography>
    <Grid container spacing={3}>
      {selectedProjectsData.map((project, index) => (
        
        <Grid item xs={12} sm={6} md={3} key={index}>
  <Card 
    style={{ 
      marginTop: '1em', 
      backgroundColor: 'white', 
      color: 'black', 
      borderRadius: '10px', 
      padding: '0.2em',
      transition: 'transform 0.3s ease-in-out',
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
      minHeight: '90%', // change height to minHeight
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      cursor: 'pointer'
    }}
    onMouseOver={e => e.currentTarget.style.transform = 'translateY(-10px)'} 
    onMouseOut={e => e.currentTarget.style.transform = 'translateY(0)'}
    onClick={() => onProjectClick(project)} 
  >
    <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '0em' }}>{project.title}</Typography>
    <CardMedia
      component="img"
      height="140"
      image={project.imageUrl} 
      alt={project.title} 
      style={{ marginBottom: '0em' }}
    />
  </Card>
  </Grid>
      ))}
    </Grid>
    {selectedProject && (
  <LightboxBackground onClick={onLightboxClick}>
    <LightboxContent style={{ color: 'black' }}> 
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ marginBottom: '0.5em' }}>{selectedProject.title}</h1>
        {selectedProject.title === 'UX Redesign of the Value Analysis Canada Website with Interactive Before/After Slider' ? (
          <div style={{ maxWidth: '450px', margin: '0 auto' }}>
            <ReactBeforeSliderComponent
              firstImage={{ imageUrl: selectedProject.beforeAfterImages.before }}
              secondImage={{ imageUrl: selectedProject.beforeAfterImages.after }}
            />
          </div>
        ) : selectedProject.title === 'Award Winning CAD of an Automated Fast Food Restaurant (CAD|MADNESS 1st Place)' ? (
            <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
          <Carousel>
            <div>
              <img src = {CM1} />
            </div>
            <div>
              <img src= {CM2} />
            </div>
            <div>
              <img src= {CM3} />
            </div>
            <div>
              <img src= {CM4} />
            </div>
            <div>
              <img src= {CM5} />
            </div>
            <div>
              <img src= {CM6} />
            </div>
            <div>
              <img src= {CM7} />
            </div>
          </Carousel>
          </div>
        ) : (
          <img
            height="500"
            src={selectedProject.imageUrl}
            alt={selectedProject.title} 
            style={{ marginBottom: '0.5em' }}
          />
        )}
      </div>
      {Array.isArray(selectedProject.description) 
        ? selectedProject.description.map((paragraph, index) => (
            <p style={{ textAlign: 'left' }} key={index}>{paragraph}</p>
          ))
        : <p style={{ textAlign: 'left' }}>{selectedProject.description}</p>
      }
      {selectedProject.buttonData && selectedProject.buttonData.map((button, index) => 
        <button 
          key={index}
          style={{
            backgroundColor: 'black', 
            color: 'white', 
            padding: '1em', 
            borderRadius: '20px',
            border: 'none', 
            cursor: 'pointer',
            marginTop: '1em',
            marginRight: '1em',
          }}
          onClick={() => window.open(button.url, '_blank')}
        >
          {button.text}
        </button>
      )}
    </LightboxContent>
  </LightboxBackground>
)}

  </AccordionDetails>
</Accordion>
</Container>

{/* Awards Section */}
<Container 
  style={{
    marginTop: '0.2em',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '10px',
    maxWidth: '80%',
    transition: 'all 0.5s ease',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  }}
>
<Accordion style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px' }}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
    aria-controls="panel-awards-content"
    id="panel-awards-header"
    style={{ padding: '0em 0em' }}
  >
    <Typography variant="h6" style={{ fontWeight: 'bold' }}>Awards</Typography>
  </AccordionSummary>
  <AccordionDetails style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px' }}>
    {/* Awards Details */}
    {awardsData.map((award, index) => (
      <Accordion key={index} style={{ marginTop: '1em', backgroundColor: 'white', color: 'black', borderRadius: '10px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
          aria-controls={`panel-award${index}-content`}
          id={`panel-award${index}-header`}
          style={{ padding: '0em 0.5em' }}
        >
          <Grid container justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={9}>
              <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'left' }}>{award.name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" style={{ textAlign: 'right' }}>{award.year}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: 'white', color: 'black', borderRadius: '10px' }}>
          <Typography style={{ textAlign: 'left' }}>
            {award.description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </AccordionDetails>
</Accordion>
</Container>





    </Box>
  );
}

export default CVPage;